import { Expose, instanceToPlain, plainToInstance, Transform } from 'class-transformer'
import type { PersonName, PersonNameFull, PersonNameNatural } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'

type Model = PersonNameFull
export type SerializedPersonNameResource = Model

export default class PersonNameResource implements CamelCaseKeys<SerializedPersonNameResource> {
    id?: Model['id']

    first?: Model['first']

    last?: Model['last']

    middle?: Model['middle']

    nick?: Model['nick']

    maiden?: Model['maiden']

    constructor(values: PersonNameResource) {
        Object.assign(this, values)
    }
}
