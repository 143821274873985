import showEmailEditor from '@/functions/emailEditor'

export default [
    {
        path: `/r/people`,
        component: () => import('@/pages/people/index.vue')
    },
    {
        path: `/people/view/:personId`,
        component: () => import('@/pages/people/Person.vue'),
        meta: {
            isVue: true,
            isLegacyRoute: true,
            feature: `vue-people`,
            sideNav: `people`
        }
    },
    {
        path: `/people`,
        component: () => import('@/pages/people/index.vue'),
        children: [
            {
                path: `archives`,
                component: () => import('@/pages/people/index.vue')
            }
        ]
    },
    {
        path: `/people/saved`,
        component: () => import('@/pages/people/Saved.vue'),
        meta: {
            isVue: true,
            feature: `vue-people`,
            sideNav: `people`
        }
    },
    ...(showEmailEditor()
        ? [
              {
                  path: `/people/view/:personId`,
                  component: () => import('@/components/people/EmailEditor.vue')
              },
              {
                  path: `/people/filter`,
                  component: () => import('@/components/people/EmailEditor.vue')
              }
          ]
        : [])
]
