import { Expose } from 'class-transformer'
import type { PeopleDetailFamilyMemberResource as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'

export type SerializedPeopleDetailFamilyMemberResource = Model

export default class PeopleDetailFamilyMemberResource
    implements CamelCaseKeys<SerializedPeopleDetailFamilyMemberResource>
{
    id?: Model['id']

    @Expose({ name: 'family_role' })
    familyRole?: Model['family_role']

    @Expose({ name: 'family_id' })
    familyId?: Model['family_id']

    name?: Model['name']

    naturalName?: Model['naturalName']

    @Expose({ name: 'profile_picture' })
    profilePicture?: Model['profile_picture']

    constructor(values: PeopleDetailFamilyMemberResource) {
        Object.assign(this, values)
    }
}
