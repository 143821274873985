import { Expose } from 'class-transformer'
import type { PersonAddressResource as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'

export type SerializedPersonAddressResource = Model

export default class PersonAddressResource implements CamelCaseKeys<SerializedPersonAddressResource> {
    @Expose({ name: 'street_address' })
    streetAddress?: Model['street_address']

    city?: Model['city']

    state?: Model['state']

    zip?: Model['zip']

    longitude?: Model['longitude']

    latitude?: Model['latitude']

    @Expose({ name: 'is_private' })
    isPrivate?: Model['is_private']

    constructor(values: PersonAddressResource) {
        Object.assign(this, values)
    }
}
