import type { SideNav } from '@/types/sideNav'

const SIDE_NAV: SideNav = {
    account: [
        {
            label: `General Settings`,
            icon: `home`,
            route: `/account/account_settings`,
            roles: [`account_settings`]
        },
        {
            label: `Bills & Payment`,
            icon: `bill`,
            route: `/r/account/bills-and-payment`,
            roles: [`account_payment_settings`]
        },
        {
            label: `Log`,
            icon: `list`,
            route: `/account/log`,
            roles: [`account_log`]
        },
        {
            label: `Api Key`,
            icon: `code`,
            route: `/account/api`,
            roles: [`extensions_api`]
        },
        {
            label: `Restore`,
            icon: `history`,
            route: `/account/restore`,
            roles: [`account_restore`]
        },
        // Backups and factory reset should be in helm
        {
            label: `Export`,
            icon: `download`,
            route: `/account/export`,
            roles: [`account_export`]
        },
        {
            label: `Cancel Account`,
            icon: `cancel`,
            route: `/account/cancel_account`,
            roles: [`account_settings`]
        },
        {
            label: `Tithe.ly`,
            icon: `tithely`,
            route: `/account/tithely`,
            roles: [`account`, `contributions_online_giving`, `users`, `marketplace`],
            features: [`tithely-processing`]
        }
    ],
    admin: [
        {
            label: `Users`,
            icon: `user`,
            route: `/admin/users`,
            roles: [`account_settings`]
        },
        {
            label: `Roles`,
            icon: `roles`,
            route: `/roles/all`,
            roles: [`account_settings`]
        },
        {
            label: `Members`,
            icon: `people`,
            route: `/admin/members`,
            roles: [`account_settings`]
        },
        {
            label: `Role Settings`,
            icon: `settings`,
            route: `/roles/settings`,
            roles: [`account_settings`],
            features: [`vue-users`]
        }
    ],
    events: [
        {
            label: `Overview`,
            icon: `chart-bar`,
            route: `/events/view/{instanceId}`,
            roles: [`events_report`]
        },
        {
            label: `Attendees`,
            icon: `user`,
            route: `/events/people/{instanceId}`,
            roles: [`events_report`]
        },
        {
            label: `Plan`,
            icon: `plan`,
            route: `/events/{instanceId}/plan`,
            roles: [`events_view`],
            features: [`service-planning`]
        },
        {
            label: `Reports`,
            icon: `report`,
            route: `/events/reports/{instanceId}`,
            roles: [`events_report`]
        },
        {
            label: `Attachments`,
            icon: `attachments`,
            route: `/events/{instanceId}/attachments`,
            roles: [`events_view_later`],
            features: [`service-planning`]
        },
        {
            label: `Check-in`,
            icon: `check`,
            route: `/checkin/v3/{instanceId}`,
            roles: [`checkin_general`]
        },
        {
            label: `Alerts`,
            icon: `notification`,
            route: `/events/alerts/{instanceId}`,
            roles: [`events_report`]
        },
        {
            label: `Volunteers`,
            icon: `volunteers`,
            route: `/events/volunteers2/{instanceId}`,
            roles: [`events_volunteers`]
        },
        {
            label: `Settings`,
            icon: `settings`,
            route: `/events/settings/{instanceId}`,
            roles: [`events_modify`]
        }
    ],
    people: [
        {
            label: `People`,
            icon: `people`,
            route: `/people`,
            roles: [`people`]
        },
        {
            label: `Saved Filters`,
            icon: `filters`,
            route: `/r/people/saved`,
            roles: [`people`]
        },
        {
            label: `Tags`,
            icon: `tags`,
            route: `/r/people/tags`,
            roles: [`people`]
        },
        {
            label: `Archives`,
            icon: `archives`,
            route: `/people/archives`,
            roles: [`people`]
        }
    ],
    history: [
        {
            label: `People`,
            icon: `user`,
            route: `/history/people`
        },
        {
            label: `Tags`,
            icon: `tags`,
            route: `/history/tags`
        },
        {
            label: `Events`,
            icon: `events`,
            route: `/history/events`
        },
        {
            label: `Email`,
            icon: `email`,
            route: `/history/email`
        },
        {
            label: `Text`,
            icon: `text`,
            route: `/history/text`
        }
    ]
}

export default SIDE_NAV
