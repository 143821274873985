/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GroupResource } from '../models/GroupResource';
import type { paginationLinks } from '../models/paginationLinks';
import type { paginationMeta } from '../models/paginationMeta';
import type { StoreGroupRequest } from '../models/StoreGroupRequest';
import type { UpdateGroupRequest } from '../models/UpdateGroupRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GroupsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Display all groups.
     * @returns any OK
     * @throws ApiError
     */
    public getGroups({
        page,
        search,
        perPage = 50,
    }: {
        /**
         * The page to retrieve starting with page 1.
         */
        page?: number,
        /**
         * Search Groups by name.
         */
        search?: string,
        /**
         * Number of items per page.
         */
        perPage?: number,
    }): CancelablePromise<{
        data: Array<GroupResource>;
        meta: paginationMeta;
        links: paginationLinks;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/groups',
            query: {
                'page': page,
                'search': search,
                'per_page': perPage,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create a new group.
     * @returns any Created
     * @throws ApiError
     */
    public createGroup({
        requestBody,
    }: {
        /**
         * Group details
         */
        requestBody: StoreGroupRequest,
    }): CancelablePromise<{
        data: GroupResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/groups',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Display the specified group.
     * @returns any OK
     * @throws ApiError
     */
    public getGroup({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: GroupResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Remove the specified group.
     * @returns void
     * @throws ApiError
     */
    public deleteGroup({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update the specified group.
     * @returns any OK
     * @throws ApiError
     */
    public updateGroup({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Song details
         */
        requestBody: UpdateGroupRequest,
    }): CancelablePromise<{
        data: GroupResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/groups/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
