import { Expose } from 'class-transformer'
import type { PersonEmailResource as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'

export type SerializedPersonEmailResource = Model

export default class PersonEmailResource implements CamelCaseKeys<SerializedPersonEmailResource> {
    address?: Model['address']

    private?: Model['private']

    doNotEmail?: Model['doNotEmail']

    constructor(values: PersonEmailResource) {
        Object.assign(this, values)
    }
}
