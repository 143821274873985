import { Expose, instanceToPlain, plainToInstance, Transform } from 'class-transformer'
import type { PeopleDetailPersonDetailsResource as Model } from '@/services/api/client'
import type { CamelCaseKeys } from '@/types/utility'
import PersonNameResource from './PersonNameResource'
import PersonAddressResource from './PersonAddressResource'
import { map } from 'lodash'
import PersonPhoneResource from './PersonPhoneResource'
import PersonEmailResource from './PersonEmailResource'

export type SerializedPeopleDetailPersonDetailsResource = Model

export default class PeopleDetailPersonDetailsResource implements CamelCaseKeys<PeopleDetailPersonDetailsResource> {
    @Transform(({ value }) => plainToInstance(PersonNameResource, value), { toClassOnly: true })
    @Transform(({ value }) => instanceToPlain(value), { toPlainOnly: true })
    name?: Model['name']

    @Expose({ name: 'profile_picture' })
    profilePicture?: Model['profile_picture']

    archived?: Model['archived']

    @Transform(({ value }) => plainToInstance(PersonAddressResource, value), { toClassOnly: true })
    @Transform(({ value }) => instanceToPlain(value), { toPlainOnly: true })
    address?: Model['address']

    birthdate?: Model['birthdate']

    @Transform(
        ({ value }) => {
            return Object.fromEntries(
                Object.entries(value).map(([key, item]) => [key, plainToInstance(PersonPhoneResource, item)])
            )
        },
        { toClassOnly: true }
    )
    @Transform(
        ({ value }) => {
            return Object.fromEntries(Object.entries(value).map(([key, item]) => [key, instanceToPlain(item)]))
        },
        { toPlainOnly: true }
    )
    phone?: Model['phone']

    @Transform(({ value }) => plainToInstance(PersonEmailResource, value), { toClassOnly: true })
    @Transform(({ value }) => instanceToPlain(value), { toPlainOnly: true })
    email?: Model['email']

    constructor(values: PeopleDetailPersonDetailsResource) {
        Object.assign(this, values)
    }
}
