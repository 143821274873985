/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArchivedPersonResource } from '../models/ArchivedPersonResource';
import type { ArchivePeopleRequest } from '../models/ArchivePeopleRequest';
import type { BulkDeletePeopleRequest } from '../models/BulkDeletePeopleRequest';
import type { DeleteArchivedPeopleRequest } from '../models/DeleteArchivedPeopleRequest';
import type { PeopleActionSearchFilter } from '../models/PeopleActionSearchFilter';
import type { PeopleActionSearchResponse } from '../models/PeopleActionSearchResponse';
import type { PersonAddressResource } from '../models/PersonAddressResource';
import type { PersonBirthdateResource } from '../models/PersonBirthdateResource';
import type { PersonCreateRequest } from '../models/PersonCreateRequest';
import type { PersonDetailResource } from '../models/PersonDetailResource';
import type { PersonEmailResource } from '../models/PersonEmailResource';
import type { PersonNameResource } from '../models/PersonNameResource';
import type { PersonPhoneResource } from '../models/PersonPhoneResource';
import type { PersonSearchResource } from '../models/PersonSearchResource';
import type { PersonSearchResponse } from '../models/PersonSearchResponse';
import type { ProfileAddress } from '../models/ProfileAddress';
import type { ProfileBirthdate } from '../models/ProfileBirthdate';
import type { ProfileEmailAddress } from '../models/ProfileEmailAddress';
import type { ProfileName } from '../models/ProfileName';
import type { StorePhoneRequest } from '../models/StorePhoneRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PeopleService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get a list of People.
     * For more search options, use the `people/search` endpoint.
     * The API specs do not include information about the "People Filter" as this may change in the near future.
     * The sortable fields listed in the API specs are DB fields. You can also sort the collection fields in the response.
     * @returns any OK
     * @throws ApiError
     */
    public getPeople({
        page,
        pageSize,
        sort,
        collectionFilter,
        exact = false,
        limit = 100,
        idsOnly,
        noLimit,
    }: {
        /**
         * The page to retrieve starting with page 1.
         */
        page?: number,
        /**
         * The number of resources to return. Default: 25
         */
        pageSize?: number,
        /**
         * Sortable fields
         * * first_name
         * * last_name
         * * middle_name
         * * nick_name
         * * maiden_name
         * * age
         * * grade
         * * phone
         * * email
         * * street_address
         * * city
         * * state
         * * zip
         * * longitude
         * * latitude
         * * family_role
         * * formalName
         * * familyLastName
         * * familyId
         *
         *
         * See **Sorting** above for more details about sorting.
         */
        sort?: string,
        /**
         *
         * Filtered fields:
         * | Field | Field Type | Description |
         * |--|--|--|
         * |first_name|text|Search by first name.|
         * |last_name|text|Search by last name.|
         * |nick_name|text|Search by nickname.|
         * |middle_name|text|Search by middle name.|
         * |maiden_name|text|Search by maiden name.|
         * |created_on|date|Search by creation date.|
         * |include_family|boolean|Include the family. `filter[include_family:eq:boolean]=true`|
         *
         * See **Filters** above for more details about using filters.
         */
        collectionFilter?: string,
        /**
         * must be set to true when combining `collection_filter` with other filters.
         */
        exact?: boolean,
        /**
         * The number of people to return.
         */
        limit?: number,
        /**
         * Return an array of IDs only.  Must also set `no_limit` to true. Only used with `collection_filter`.
         */
        idsOnly?: boolean,
        /**
         * Must be set to true.  Returns all results. Only used with `collection_filter`.
         */
        noLimit?: boolean,
    }): CancelablePromise<{
        data: Array<(PersonSearchResource | PersonDetailResource | Array<number>)>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people',
            query: {
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
                'collection_filter': collectionFilter,
                'exact': exact,
                'limit': limit,
                'ids_only': idsOnly,
                'no_limit': noLimit,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create or update an array of person records.
     * @returns any Created
     * @throws ApiError
     */
    public personCreate({
        requestBody,
    }: {
        requestBody: PersonCreateRequest,
    }): CancelablePromise<{
        data: Array<PersonDetailResource>;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Get archived people
     * @returns any OK
     * @throws ApiError
     */
    public getArchivedPeople(): CancelablePromise<{
        data: PersonSearchResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/archive',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Archive people
     * @returns any OK
     * @throws ApiError
     */
    public archivePersonCreate({
        requestBody,
    }: {
        /**
         * Email payload
         */
        requestBody: ArchivePeopleRequest,
    }): CancelablePromise<{
        data: ArchivedPersonResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/archive',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Bulk Destroy people
     * @returns any OK
     * @throws ApiError
     */
    public bulkDeletePeople({
        requestBody,
    }: {
        /**
         * Bulk delete payload
         */
        requestBody: BulkDeletePeopleRequest,
    }): CancelablePromise<{
        data: PersonDetailResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/delete',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Get people for emails?
     * @returns any OK
     * @throws ApiError
     */
    public getPeopleEmails({
        requestBody,
    }: {
        requestBody?: PeopleActionSearchFilter,
    }): CancelablePromise<{
        data: Array<PeopleActionSearchResponse>;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/emails',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public getPeoplePhones({
        requestBody,
    }: {
        requestBody?: PeopleActionSearchFilter,
    }): CancelablePromise<{
        data: Array<PeopleActionSearchResponse>;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/phones',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Similar to index but with additional search options.
     * You must supply at least one filter or you will get a 404 response.
     * The API specs do not include information about the "People Filter" as this may change in the near future.
     * The sortable fields listed in the API specs are DB fields. You can also sort the collection fields in the response.
     *
     *
     * NOTE: Should move to the regular index.
     * @returns any OK
     * @throws ApiError
     */
    public personSearch({
        page,
        pageSize,
        sort,
        idsOnly,
        noLimit,
    }: {
        /**
         * The page to retrieve starting with page 1.
         */
        page?: number,
        /**
         * The number of resources to return. Default: 25
         */
        pageSize?: number,
        /**
         * Sortable fields
         * * first_name
         * * last_name
         * * middle_name
         * * nick_name
         * * maiden_name
         * * age
         * * grade
         * * phone
         * * email
         * * street_address
         * * city
         * * state
         * * zip
         * * longitude
         * * latitude
         * * family_role
         * * formalName
         * * familyLastName
         * * familyId
         *
         *
         * See **Sorting** above for more details about sorting.
         */
        sort?: string,
        /**
         * Return an array of IDs only.  Must also set `no_limit` to true
         */
        idsOnly?: boolean,
        /**
         * Return an array of IDs only.  Must also set `no_limit` to true
         */
        noLimit?: boolean,
    }): CancelablePromise<{
        data: PersonSearchResponse;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/search',
            query: {
                'page': page,
                'pageSize': pageSize,
                'sort': sort,
                'ids_only': idsOnly,
                'no_limit': noLimit,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Delete archived people permanently
     * @returns any OK
     * @throws ApiError
     */
    public unarchivePeople({
        requestBody,
    }: {
        /**
         * Filters to find people to delete
         */
        requestBody: DeleteArchivedPeopleRequest,
    }): CancelablePromise<{
        data: ArchivedPersonResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/unarchive',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Get one person.
     * @returns any OK
     * @throws ApiError
     */
    public getOnePerson({
        id,
        include,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Fields to include with people resource.
         */
        include: string,
    }): CancelablePromise<{
        data: PersonDetailResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/people/{id}',
            path: {
                'id': id,
            },
            query: {
                'include': include,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Delete a person
     * @returns void
     * @throws ApiError
     */
    public deletePerson({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/people/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get address of person
     * @returns any OK
     * @throws ApiError
     */
    public getAddress({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: PersonAddressResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{id}/address',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update just the address
     * @returns any OK
     * @throws ApiError
     */
    public storeAddress({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Address metadata.
         */
        requestBody: ProfileAddress,
    }): CancelablePromise<{
        data: PersonAddressResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/{id}/address',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get birthday of person
     * @returns any OK
     * @throws ApiError
     */
    public getBirthdate({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: PersonBirthdateResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{id}/birthdate',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update just the birthday
     * @returns any OK
     * @throws ApiError
     */
    public storeBirthdate({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Birthdate metadata.
         */
        requestBody: ProfileBirthdate,
    }): CancelablePromise<{
        data: PersonBirthdateResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/{id}/birthdate',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get email of person
     * @returns any OK
     * @throws ApiError
     */
    public getEmail({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: PersonEmailResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{id}/email',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Save just the email
     * @returns any OK
     * @throws ApiError
     */
    public storeEmail({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Email address metadata.
         */
        requestBody: ProfileEmailAddress,
    }): CancelablePromise<{
        data: PersonEmailResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/{id}/email',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get name of person
     * @returns any OK
     * @throws ApiError
     */
    public getName({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: PersonNameResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{id}/name',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update just a person's name
     * @returns any OK
     * @throws ApiError
     */
    public storeName({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Name metadata.
         */
        requestBody: ProfileName,
    }): CancelablePromise<{
        data: PersonNameResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/{id}/name',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get phone of person
     * @returns any OK
     * @throws ApiError
     */
    public getPhone({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: PersonPhoneResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/{id}/phone',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update just the phone
     * @returns any OK
     * @throws ApiError
     */
    public storePhone({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        /**
         * Phone number metadata.
         */
        requestBody: StorePhoneRequest,
    }): CancelablePromise<{
        data: PersonPhoneResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/{id}/phone',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
