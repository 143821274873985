import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { filter, map } from 'lodash'
import { useMe } from '@/queries/me'
import NAV_SETTINGS from '@/constants/nav/settings'
import type { NavSettingsItem, NavSettingsSection } from '@/types/nav/settings'
import { filterNavPermissions } from '@/functions/nav'

const useNavSettings = () => {
    const { data: me, hasFeature } = useMe()
    const { path } = useRoute()

    const orgStatus = computed(() => me.value?.organization.status || 0)
    const features = computed(() => me.value?.features ?? [])
    const roles = computed(() => me.value?.roles ?? [])

    const filteredNav = (section: NavSettingsSection): NavSettingsItem[] => {
        if (path === `/r/suspended`) {
            return []
        }

        let items = NAV_SETTINGS[section]
        if (orgStatus.value === 0) {
            items = filter(items, item => item.action !== `launchReferral`)
        }

        return filterNavPermissions(items, features, roles)
    }

    // Show prompt to upgrade if Song Library not enabled
    const orgNav = computed(() => {
        const nav = filteredNav(`organization`)
        return map(nav, (item: NavSettingsItem) => {
            if (item.label === 'Song Library' && !hasFeature(`service_plan_integration`)) {
                delete item.action
                item.route = '/extensions?name=service_plan_integration'
                item.badge = 'Upgrade'
                item.tag = 'Click here to upgrade'
            }
            return item
        })
    })

    const userNav = computed(() => filteredNav(`user`))
    return { orgNav, userNav }
}

export default useNavSettings
