export default {
    bulkSms: {
        title: `New Text`,
        to: `To:`,
        send: `Send Now`,
        search: `Search for contacts...`,
        placeholder: `Start typing your text...`,
        linkPhoto: `Link to Photo or File`,
        shortenUrl: `Shorten a URL`,
        charactersRemaining: `%{count} Characters remaining`,
        change: `change`,
        options: {
            sendNow: `Send Now`,
            sendCustom: `Send on custom date and time`,
            sendTomorrow3: `Send tomorrow at 3pm`,
            sendTomorrow9: `Send tomorrow at 9am`,
            sendIn6: `Send in 6 hours`,
            am: `AM`,
            pm: `PM`
        },
        instructions: `When linking to a photo or file, Breeze uses a URL shortener to give you more space to type your message`,
        textRemain: `%{count} Texts Remain this Month`,
        searching: `Searching...`,
        searchContacts: `Search for contacts...`,
        noNumber: `No mobile number`,
        alreadyRecipient: `Already a recipient`,
        loadingContacts: `Loading contacts...`,
        success: `Text sent successfully!`,
        date: `Date`,
        time: `Time`,
        validation: {
            mustHaveAtleast: `Must have atleast 1 recipient.`,
            recipientsRequired: `Recipients are required.`,
            messageRequired: `Message is required.`
        }
    },
    addFamilyMember: {
        title: `Add Family Member`,
        options: {
            newPerson: {
                title: `New Person`,
                description: `Add a new person to the database as a member of this family.`
            },
            existingPerson: {
                title: `Existing Person`,
                description: `Select a person already in the database to associate as a family member.`
            }
        },
        cancel: `Cancel`
    },
    displayFields: {
        title: `Display Fields`,
        instructions: `Select the profile fields you'd like to include when adding new people. These preferences will be saved for your user. In order to add additional field options, you will need to add a custom field in the Profile Fields section.`
    },
    bulkUpdateModal: {
        title: `Bulk Update People`,
        personConfirmButton: `Update 1 Person`,
        peopleConfirmButton: `Update %{peopleCount} People`,
        confirmText: `This action will update all selected profiles with the information provided below.
        Applies to: %{peopleCount} person selected from your list`,
        successUpdate: `Updated people successfully`,
        errorUpdate: `Failed to update people.`
    },
    bulkArchiveModal: {
        title: `Bulk Archive People`,
        personConfirmButton: `Archive 1 Person`,
        peopleConfirmButton: `Archive %{peopleCount} People`,
        confirmText: `Archiving people will remove them from the main People page, general search results, and family member profiles. All of their information will be maintained and accessible. This action can be undone.
        Applies to: %{peopleCount} person selected from your list`,
        successArchive: `Archived people successfully`,
        errorArchive: `Failed to archive people.`
    },
    bulkUnarchiveModal: {
        title: `Bulk Unarchive People`,
        personConfirmButton: `Unarchive 1 Person`,
        peopleConfirmButton: `Unarchive %{peopleCount} People`,
        confirmText: `Unarchiving people will add them back into the main People page, general search results, family member profiles, and the Tags they were in before being archived.
        Applies to: %{peopleCount} person selected from your list`,
        successArchive: `Unarchived people successfully`,
        errorArchive: `Failed to unarchive people.`
    },
    person: {
        noInfo: `No information stored.`,
        noContactInfoAvailable: `No contact information available.`,
        familyRole: {
            unassigned: `Unassigned`,
            child: `Child`,
            adult: `Adult`,
            headOfHousehold: `Head of Household`,
            spouse: `Spouse`
        }
    }
}
