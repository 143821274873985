/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PeopleActionSearchSort = {
    /**
     * The field to sort by
     */
    field?: string;
    /**
     * The direction to sort by (asc or desc)
     */
    order?: PeopleActionSearchSort.order;
};

export namespace PeopleActionSearchSort {

    /**
     * The direction to sort by (asc or desc)
     */
    export enum order {
        ASC = 'asc',
        DESC = 'desc',
    }


}

