/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserRequest } from '../models/CreateUserRequest';
import type { UserResource } from '../models/UserResource';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all users. Can filter and sort.
     * @returns any OK
     * @throws ApiError
     */
    public getUsers({
        sort,
        filter,
    }: {
        /**
         * Sort users by a column.
         */
        sort?: string,
        /**
         * Filter users.
         */
        filter?: Record<string, any>,
    }): CancelablePromise<{
        data: Array<UserResource>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users',
            query: {
                'sort': sort,
                'filter': filter,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Create new user.
     * @returns any Created
     * @throws ApiError
     */
    public createUser({
        requestBody,
    }: {
        requestBody: CreateUserRequest,
    }): CancelablePromise<{
        data: UserResource;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Returns the token of the user who began impersonation
     * @returns any OK
     * @throws ApiError
     */
    public leaveImpersonation(): CancelablePromise<{
        data: {
            access_token: string;
        };
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/leaveImpersonation',
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

    /**
     * Get specific user info.
     * @returns any OK
     * @throws ApiError
     */
    public getUser({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<{
        data: UserResource;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteUser({
        id,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update a user's info.
     * @returns any OK
     * @throws ApiError
     */
    public updateUser({
        id,
        requestBody,
    }: {
        /**
         * ID of the retrieved resource.
         */
        id: number,
        requestBody: {
            username?: string;
            email?: string;
            send_from?: string;
            current_password?: string;
            password?: string;
            role_id?: number;
            first_name?: string;
            last_name?: string;
            disable_mfa?: boolean;
            make_primary?: boolean;
        },
    }): CancelablePromise<{
        data: UserResource;
    }> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
                404: `Not Found`,
            },
        });
    }

}
