export default [
    {
        path: `/admin/users`,
        component: () => import('@/pages/admin/Users.vue'),
        meta: {
            isVue: true,
            isLegacyRoute: true,
            sideNav: `admin`,
            feature: `vue-users`
        }
    },
    {
        path: `/roles/all`,
        component: () => import('@/pages/admin/Roles.vue'),
        meta: {
            isVue: true,
            sideNav: `admin`,
            isLegacyRoute: true,
            feature: `vue-users`
        }
    },
    {
        path: `/admin/members`,
        component: () => import('@/pages/Blank.vue'),
        meta: {
            isLegacyRoute: true,
            feature: `vue-users`
        }
    }
]