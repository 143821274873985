/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BulkUpdatePeopleRequest } from '../models/BulkUpdatePeopleRequest';
import type { PersonSearchResponse } from '../models/PersonSearchResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PeopleBulkOperationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Execute bulk update to people
     * @returns any OK
     * @throws ApiError
     */
    public bulkUpdatePeople({
        requestBody,
    }: {
        requestBody?: BulkUpdatePeopleRequest,
    }): CancelablePromise<{
        data: Array<PersonSearchResponse>;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/people/bulk/update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `User is unauthorized.`,
                403: `Access Denied`,
            },
        });
    }

}
