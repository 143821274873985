import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { computed } from 'vue'
import { some, map, every, includes } from 'lodash'
import { plainToInstance } from 'class-transformer'
import { useRoute } from 'vue-router'
import useTithelySSO from '@/composables/sso'
import { QUERY_KEYS } from '@/constants/query'
import useApi from '@/composables/api'
import { useMe } from '@/queries/me'
import TithelySubscription from '@/services/api/transformers/TithelySubscription'

const useTithely = () => {
    const { api } = useApi()
    const route = useRoute()
    const queryClient = useQueryClient()
    const { redirect, isLoading: isRedirecting } = useTithelySSO()
    const { data: me } = useMe()

    const { mutateAsync: startLink } = useMutation({
        mutationFn: ({ extensionId }: { extensionId?: number }) =>
            api.tithely
                .startTithelyLink({
                    requestBody: {
                        target: extensionId ? `${route.path}?extension_id=${extensionId}` : route.path
                    }
                }) // Might want to wait for get query to finish?
                .then(response => {
                    if (response.data?.link) {
                        return response.data.link
                    }

                    return Promise.reject(response.data)
                })
    })

    const { mutateAsync: finishLink, isPending: isLoadingFinish } = useMutation({
        mutationFn: () => api.tithely.updateTithelyLink(),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEYS.me })
            queryClient.setQueryData(QUERY_KEYS.accountTithelyLink(), () => ({
                linked: true
            }))
        }
    })

    const { mutateAsync: migrateBilling, isPending: isLoadingBilling } = useMutation({
        mutationKey: [QUERY_KEYS.accountTithelyLink],
        mutationFn: () => api.tithely.migrateTithelyBilling(),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: QUERY_KEYS.me })
    })

    const { mutateAsync: score, isPending: isLoadingScore } = useMutation({
        mutationKey: [QUERY_KEYS.accountTithelyLink],
        mutationFn: () => api.tithely.findTithelyOrgByScore()
    })

    const { data: billing, isLoading: isLoadingSubscription } = useQuery({
        queryKey: QUERY_KEYS.accountTithelySubscription(),
        queryFn: () => api.tithely.getTithelySubscription(),
        select: ({ data }) => plainToInstance(TithelySubscription, data),
        enabled: computed(() => Boolean(me.value?.organization.tithelyOrganizationId))
    })

    const { mutateAsync: createOrganization, isPending: isLoadingCreate } = useMutation({
        mutationKey: [QUERY_KEYS.accountTithelyLink],
        mutationFn: () => api.tithely.createTithelyOrganization()
    })

    const { mutateAsync: syncTithelyBilling, isPending: isLoadingSync } = useMutation({
        mutationKey: [QUERY_KEYS.accountTithelyLink],
        mutationFn: () => api.tithely.syncTithelyBilling(),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEYS.me })
        },
        retry: false
    })

    // Taking our breeze extension names and checking if they exist in tithely's billing data by their corresponding tithely product code.
    const hasProduct = (key: string | undefined) =>
        some(billing.value?.products ?? [], item => {
            switch (key) {
                case `service_plan_integration`:
                    return item.code === `service_planning`
                case `sites_integration`:
                    return item.code === `websites`
                case `church_apps_integration`:
                    return item.code === `apps`
            }
        })

    const isLoading = computed(
        () =>
            isLoadingScore.value ||
            isLoadingBilling.value ||
            isLoadingCreate.value ||
            isLoadingSubscription.value ||
            isLoadingSync.value
    )

    return {
        billing,
        isLoading,
        isLoadingFinish,
        isRedirecting,
        createOrganization,
        finishLink,
        redirect,
        score,
        startLink,
        hasProduct,
        migrateBilling,
        syncTithelyBilling
    }
}

const useTithelyBundle = () => {
    const { api } = useApi()
    const { billing, isLoading } = useTithely()
    const { isLinked, hasFeature, hasGiving } = useMe()

    const query = useQuery({
        queryKey: QUERY_KEYS.accountTithelyBundle(),
        queryFn: () => api.tithely.getBundleOffer().then(({ data }) => data),
        enabled: computed(() => Boolean(isLinked.value))
    })

    const hasBundle = computed(() => some(billing.value?.plans ?? [], { code: `breeze-bundle` }))

    const hasCHMSPlan = computed(() =>
        some(billing.value?.plans ?? [], plan => includes(plan.productCodes, `chms`) && plan.cancelledAt === null)
    )

    const bundleCodes = ['breeze', 'websites', 'apps', 'messaging']

    const hasAllBundleProducts = computed(() => {
        const productCodes = map(billing.value?.products ?? [], product => product.code)
        return every(bundleCodes, code => productCodes.includes(code))
    })

    const showBundleOffer = computed(
        () =>
            !isLoading.value &&
            !hasBundle.value &&
            !hasCHMSPlan.value &&
            !hasAllBundleProducts.value &&
            !hasGiving.value &&
            isLinked.value &&
            hasFeature('tithely-bundle')
    )

    return {
        ...query,
        hasBundle,
        showBundleOffer,
        hasCHMSPlan,
        hasAllBundleProducts
    }
}

const usePurchaseTithelyBundle = () => {
    const { api } = useApi()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => api.tithely.addBundleOffer(),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEYS.account })
            queryClient.invalidateQueries({ queryKey: QUERY_KEYS.extensions })
        }
    })
}

export { useTithely, useTithelyBundle, usePurchaseTithelyBundle }
